import { useEffect, useState } from "react";
import { MyVehiclesContainer } from "./style.js";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { toast } from "react-toastify";
import { Card, Col, Form, Row, Spinner, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PhoneInput from "react-phone-number-input";
import { loadMyProfile, setMyVehicleUsername, addUpdateMyVehcile, selectMyVehicle } from "../../redux/actions/myVehicleActions.js";

const ManageMyVehicles = () => {

  const [loading, setLoading] = useState(true);
  const [vLinkName, setVLinkName] = useState("");
  const [loadingError, setLoadingError] = useState(false);
  const [progress, setProgress] = useState(false);
  const [qr, setQR] = useState("");
  const [copied, setCopied] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formProgress, setFormProgress] = useState(false);
  const [vehicle, setVehicle] = useState([
    {
      vehicleMake: "",
      vehicleModel: "",
      color: "",
      vehicleNo: "",
      primaryContactName: "",
      primaryContactRelationship: "",
      primaryContactNumber: "",
      primaryContactEmailAddress: "",
      primaryContactEmailAddressIsValid: true,
      primaryContactNumberType: "Mobile",
      secondaryContactName: "",
      secondaryContactRelationship: "",
      secondaryContactNumber: "",
      secondaryContactEmailAddress: "",
      secondaryContactEmailAddressIsValid: true,
      secondaryContactNumberType: "Mobile"
    }
  ]);

  useEffect(() => {
    loadProfileDetails();
  }, []);


  useEffect(() => {
    checkValidation();
  }, [vehicle]);

  const loadProfileDetails = async () => {
    try {
      const { success, status, user } = await loadMyProfile();
      if (status || user) {
        const profile = user;
        setVLinkName(profile.id ?? "");
        setQR("http://api.qrserver.com/v1/create-qr-code/?data=" + encodeURI("https://vlink.tap1ce.com/" + (profile.id ?? "")) + "&size=100x100&bgcolor=ffffff");
      }
      else if (!success) {
        setLoading(false);
        setLoadingError(true);
      }

      loadSelectMyVehcile();
    } catch (error) {
      setLoading(false);
      setLoadingError(true);
      console.log("error - " + error);
    }
  };

  const loadSelectMyVehcile = async () => {
    try {
      const { status, VehicleDetail } = await selectMyVehicle();

      if (status && VehicleDetail.length > 0) {
        for (const key in VehicleDetail) {
          VehicleDetail[key].primaryContactEmailAddressIsValid = true;
          VehicleDetail[key].secondaryContactEmailAddressIsValid = true;
        }
        setVehicle(VehicleDetail);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setLoadingError(true);
      console.log("error - " + error);
    }
  };

  async function onSaveUsername() {
    setProgress(true);
    if (vLinkName === "") {
      setProgress(false);
      return toast.warn("Please username first.");
    }
    const result = await setMyVehicleUsername(vLinkName);
    setProgress(false);
    if (result.status) {
      setQR("http://api.qrserver.com/v1/create-qr-code/?data=" + encodeURI("https://vlink.tap1ce.com/" + vLinkName) + "&size=100x100&bgcolor=ffffff");
      return toast.success(result.message);
    } else {
      return toast.error(result.message);
    }
  }

  async function downloadQR() {
    const downloadLink = document.createElement("a");
    downloadLink.href = "api/my-vehicle/qr/" + vLinkName;
    downloadLink.download = vLinkName;  // Specify the name of the downloaded file
    document.body.appendChild(downloadLink); // Append to the DOM (needed for some browsers)
    downloadLink.click();  // Trigger the download
    document.body.removeChild(downloadLink);
  }

  function shareUrl() {
    return "https://vlink.tap1ce.com/" + vLinkName;
  }

  const onAddVehicle = async (e) => {
    e.preventDefault();
    setVehicle((vehicle) => [
      ...vehicle,
      {
        vehicleMake: "",
        vehicleModel: "",
        color: "",
        vehicleNo: "",
        primaryContactName: "",
        primaryContactRelationship: "",
        primaryContactNumber: "",
        primaryContactEmailAddress: "",
        primaryContactEmailAddressIsValid: true,
        primaryContactNumberType: "Mobile",
        secondaryContactName: "",
        secondaryContactRelationship: "",
        secondaryContactNumber: "",
        secondaryContactEmailAddress: "",
        secondaryContactEmailAddressIsValid: true,
        secondaryContactNumberType: "Mobile"
      },
    ]);
  };

  const onRemoveVehicle = async (value) => {
    const newArray = vehicle.filter(function (element) {
      return element != value;
    });
    setVehicle(newArray);
  };

  const emailPattern = /^[A-Za-z0-9!'#$%&*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!'#$%&*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[a-zA-Z]{2,64}$/;
  const onChange = async (i, type, value) => {
    vehicle[i][type] = value;
    if (type === "primaryContactEmailAddress") {
      vehicle[i]["primaryContactEmailAddressIsValid"] = false;
      if (emailPattern.test(value)) {
        vehicle[i]["primaryContactEmailAddressIsValid"] = true;
      }
    } else if (type === "secondaryContactEmailAddress") {
      vehicle[i]["secondaryContactEmailAddressIsValid"] = false;
      if (emailPattern.test(value)) {
        vehicle[i]["secondaryContactEmailAddressIsValid"] = true;
      }
    }
    setVehicle((vehicle) => [...vehicle]);
  };

  const onSave = async (e) => {
    e.preventDefault();
    setFormProgress(true);
    const result = await addUpdateMyVehcile(vehicle);
    setFormProgress(false);
    if (result.status) {
      return toast.success(result.message);
    } else {
      return toast.error(result.message);
    }
  }

  const checkValidation = async () => {
    let isValid = true;
    vehicle.every((element) => {
      if (element.carMake === "" || element.carModel === "" || element.color === "" || element.vehicleNo === "" || element.primaryContactName === "" || element.primaryContactRelationship === "" || element.primaryContactNumber === "" || element.primaryContactEmailAddress === "") {
        isValid = false;
      }
      else if (!element.primaryContactEmailAddressIsValid) {
        isValid = false;
      }
      else if (!element.secondaryContactEmailAddressIsValid) {
        isValid = false;
      }
      return isValid;
    });
    setFormValid(isValid);
  }

  return (
    <MyVehiclesContainer>
      <section className="section">
        <div className="container">
          {
            loading ? (
              <h2>Loading...</h2>
            ) : loadingError ? (
              <h2>Something went wrong!!!</h2>
            ) : (
              <>
                <div className="page-title-section">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>My Vehicle</Breadcrumb.Item>
                  </Breadcrumb>

                  <div className="page-title-section">
                    <div className="row">
                      <div className="col-lg-4">
                        <h3 className="title">My Vehicle</h3>
                        {/* <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <small>https://vlink.tap1ce.com/</small>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Username"
                            aria-label="Username"
                            aria-describedby="basic-addon2"
                            value={vLinkName}
                            pattern={/^[a-z0-9_\.]+$/}
                            onChange={(e) => {
                              setVLinkName(e.target.value);
                            }}
                          />
                          <div className="input-group-append">
                            {
                              progress ? (
                                <Spinner animation="border" variant="primary" />
                              ) : (
                                <button
                                  className="btn btn-primary"
                                  onClick={(e) => onSaveUsername()}
                                  type="button"
                                >
                                  Save
                                </button>
                              )
                            }
                          </div>
                        </div> */}
                      </div>

                      <div className="col-lg-4 col-6">
                        {qr != "" ? (
                          <div className="text-center">
                            <div
                              className=""
                              href="#"
                              onClick={(e) => downloadQR()}
                              target="_blank"
                              download
                            >
                              <img className="img img-thumbnail" src={qr}></img>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <CopyToClipboard
                          text={shareUrl()}
                          onCopy={() => {
                            setCopied(true);
                            setTimeout(() => {
                              setCopied(false);
                            }, 1500);
                          }}
                        >
                          <div className="text-center">
                            <button className="btn btn-sm btn-light mr-2">
                              Copy link
                            </button>
                            {copied ? (
                              <span className="mt-2" style={{ color: "red" }}>
                                Copied.
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </CopyToClipboard>
                      </div>

                      <div className="col-lg-4 col-6 text-right"></div>
                    </div>
                  </div>
                </div>

                <Form className="py-2" onSubmit={onSave}>
                  {
                    vehicle.map((vehicle, i) => (
                      <Card className="mt-5" key={i}>
                        <Card.Header>
                          <Row>
                            <Col>
                              <Form.Label>
                                Vehicle Details #{i + 1}
                              </Form.Label>
                            </Col>

                            <Col className="text-right">
                              {
                                i === 0 ? (
                                  <span onClick={onAddVehicle} className="btn btn-sm btn-blue-2">
                                    Add New Vehicle
                                  </span>
                                ) : (
                                  <span onClick={() => { onRemoveVehicle(vehicle); }} className="btn btn-sm btn-danger">
                                    Remove Vehicle
                                  </span>
                                )
                              }
                            </Col>
                          </Row>
                        </Card.Header>
                        <Card.Body>
                          <Card>
                            <Card.Header>
                              <Row>
                                <Col>
                                  <Form.Label>
                                    Vehicle Details
                                  </Form.Label>
                                </Col>
                              </Row>
                            </Card.Header>

                            <Card.Body>
                              <Row className="mb-3">
                                <Form.Group as={Col} lg={3} controlId="vehicleMake">
                                  <Form.Label className="required-field">
                                    Vehicle Make{" "}
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>The vehicle's manufacturer (e.g., Toyota, Ford).</Tooltip>
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <Form.Control
                                    className="main"
                                    placeholder="Enter Vehicle Make"
                                    value={vehicle.vehicleMake}
                                    onChange={(e) => onChange(i, "vehicleMake", e.target.value)}
                                    required
                                  />
                                </Form.Group>

                                <Form.Group as={Col} lg={3} controlId="vehicleModel">
                                  <Form.Label className="required-field">
                                    Vehicle Model{" "}
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>The specific model of the vehicle (e.g., Camry, Mustang).</Tooltip>
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <Form.Control
                                    className="main"
                                    placeholder="Enter Vehicle Model"
                                    value={vehicle.vehicleModel}
                                    onChange={(e) => onChange(i, "vehicleModel", e.target.value)}
                                    required
                                  />
                                </Form.Group>

                                <Form.Group as={Col} lg={3} controlId="color">
                                  <Form.Label className="required-field">
                                    Vehicle Color{" "}
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>The vehicle’s color (e.g., Red, Black).</Tooltip>
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <Form.Control
                                    className="main"
                                    placeholder="Enter Vehicle Color"
                                    value={vehicle.color}
                                    onChange={(e) => onChange(i, "color", e.target.value)}
                                    required
                                  />
                                </Form.Group>

                                <Form.Group as={Col} lg={3} controlId="vehicleNo">
                                  <Form.Label className="required-field">
                                    License Plate Number{" "}
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>The unique identifier for the vehicle (e.g., ABC1234).</Tooltip>
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <Form.Control
                                    className="main"
                                    placeholder="Enter License Plate Number"
                                    value={vehicle.vehicleNo}
                                    onChange={(e) => onChange(i, "vehicleNo", e.target.value)}
                                    required
                                  />
                                </Form.Group>
                              </Row>
                            </Card.Body>
                          </Card>

                          <Card className="mt-2">
                            <Card.Header>
                              <Row>
                                <Col>
                                  <Form.Label>
                                    Primary Person Details
                                  </Form.Label>
                                </Col>
                              </Row>
                            </Card.Header>

                            <Card.Body>
                              <Row className="mb-3">

                                <Form.Group as={Col} lg={3} controlId="primaryContactName">
                                  <Form.Label className="required-field">
                                    Contact Name{" "}
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>The main individual to contact for any inquiries or issues related to the vehicle. (e.g., John Doe)</Tooltip>
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <Form.Control
                                    className="main"
                                    placeholder="Enter Contact Name"
                                    value={vehicle.primaryContactName}
                                    onChange={(e) => onChange(i, "primaryContactName", e.target.value)}
                                    required
                                  />
                                </Form.Group>

                                <Form.Group as={Col} lg={3} controlId="primaryContactRelationship">
                                  <Form.Label className="required-field">
                                    Relationship {" "}
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>Describes how this person is associated with the car (e.g., Owner, Mechanic).</Tooltip>
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <Form.Control
                                    className="main"
                                    placeholder="Enter Relationship"
                                    value={vehicle.primaryContactRelationship}
                                    onChange={(e) => onChange(i, "primaryContactRelationship", e.target.value)}
                                    required
                                  />
                                </Form.Group>

                                <Form.Group as={Col} lg={3} controlId="primaryContactNumber">
                                  <Form.Label className="required-field">
                                    Phone Number{" "}
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>Reach this person via their mobile, work, or home number. </Tooltip>
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <PhoneInput
                                    defaultCountry="IN"
                                    placeholder="Enter Phone Number"
                                    className="main mt-2"
                                    value={vehicle.primaryContactNumber}
                                    required
                                    onChange={(e) => {
                                      console.log(e);
                                      onChange(i, "primaryContactNumber", e)
                                    }}
                                  />
                                </Form.Group>

                                <Form.Group as={Col} lg={3} controlId="primaryContactEmailAddress">
                                  <Form.Label className="required-field">
                                    Email Address{" "}
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>Reach this person via their their email address.</Tooltip>
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <Form.Control
                                    className="main"
                                    placeholder="Enter Email Address"
                                    value={vehicle.primaryContactEmailAddress}
                                    onChange={(e) => onChange(i, "primaryContactEmailAddress", e.target.value)}
                                    required
                                    isInvalid={!vehicle.primaryContactEmailAddressIsValid}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Invalid email address.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                            </Card.Body>
                          </Card>
                          <Card className="mt-2">
                            <Card.Header>
                              <Row>
                                <Col>
                                  <Form.Label>
                                    Secondary Person Details
                                  </Form.Label>
                                </Col>
                              </Row>
                            </Card.Header>

                            <Card.Body>
                              <Row className="mb-3">

                                <Form.Group as={Col} lg={3} controlId="secondaryContactName">
                                  <Form.Label>
                                    Contact Name{" "}
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>An additional person to contact if the primary contact is unavailable.</Tooltip>
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <Form.Control
                                    className="main"
                                    placeholder="Enter Contact Name"
                                    value={vehicle.secondaryContactName}
                                    onChange={(e) => onChange(i, "secondaryContactName", e.target.value)}
                                  />
                                </Form.Group>

                                <Form.Group as={Col} lg={3} controlId="secondaryContactRelationship">
                                  <Form.Label>
                                    Relationship{" "}
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>How this person is related to the vehicle (e.g., Emergency Contact, Family Member).</Tooltip>
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <Form.Control
                                    className="main"
                                    placeholder="Enter Relationship"
                                    value={vehicle.secondaryContactRelationship}
                                    onChange={(e) => onChange(i, "secondaryContactRelationship", e.target.value)}
                                  />
                                </Form.Group>

                                <Form.Group as={Col} lg={3} controlId="secondaryContactNumber">
                                  <Form.Label>
                                    Phone Number{" "}
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>Contact information for the secondary individual.</Tooltip>
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <PhoneInput
                                    defaultCountry="IN"
                                    placeholder="Enter Phone Number"
                                    className="main mt-2"
                                    value={vehicle.secondaryContactNumber}
                                    onChange={(e) => onChange(i, "secondaryContactNumber", e)}
                                  />
                                </Form.Group>

                                <Form.Group as={Col} lg={3} controlId="secondaryContactEmailAddress">
                                  <Form.Label>
                                    Email Address{" "}
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>Reach this person via their their email address.</Tooltip>
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle text-primary"
                                        aria-hidden="true"
                                      ></i>
                                    </OverlayTrigger>
                                  </Form.Label>
                                  <Form.Control
                                    className="main"
                                    placeholder="Enter Email Address"
                                    value={vehicle.secondaryContactEmailAddress}
                                    onChange={(e) => onChange(i, "secondaryContactEmailAddress", e.target.value)}
                                    isInvalid={!vehicle.secondaryContactEmailAddressIsValid}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Invalid email address.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Card.Body>
                      </Card>
                    ))
                  }

                  <Row>
                    <Col className="col-12 mt-2 text-center">
                      {formProgress ? (
                        <Spinner animation="border" variant="primary" />
                      ) : (
                        <Button
                          variant=""
                          disabled={!formValid}
                          type="submit"
                          className="btn btn-lg btn-primary"
                        >
                          Save
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>

              </>
            )
          }
        </div>
      </section>
    </MyVehiclesContainer>
  );
};

export default ManageMyVehicles;
