import styled from "styled-components";

export const MyAccountContainer = styled.div`
.avatar {
    vertical-align: middle;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .avatar-2 {
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .hello{
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    color: #1A1A1A;
  }

  .name{
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 60px;
    text-transform: uppercase;
    color: #1A1A1A;
  }

  .small-card{
    border-radius: 8px;
    position:relative;
    min-height: 100px;
  }

  .small-card .content{
    
  }

  .small-card .title{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
  }

  .small-card .msg{
    position: absolute;
    bottom: 5%;
    right: 5%;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

  .blue-card-bg{
    background: #17556D;
    
  }
  .blue-card-bg .title,.msg{
    color: #FFFFFF;
  }
  .light-card-bg{
    background: rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    border-radius: 8px;
    color: #1A1A1A;
  }

  .light-card-bg .title{
    color: #17556D;
  }

  .light-card-bg .msg{
    color: #1A1A1A;
  }

  .row .no-float {
    display: table-cell;
    float: none;
  }

  @media only screen and (max-width: 600px) {
    .name{
      font-size: 25px;
      line-height: 40px;
    }

    .small-card{
      height: 150px;
    }

    .small-card img{
      max-width: 60%;
    }


  }
  .myVehicleCard .card-content{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 5%;
  }
     
`;