import axiosInstance from '../../services/AxiosInstance';

export const loadMyProfile = async () => {  try {
  const { data } = await axiosInstance.get(`auth/profile`);
  return data;
} catch (error) {
  return {
    status: false,
    message: error.toString()
  }
}
};

export const setMyVehicleUsername = async (username) => {  try {
    let body = {
      username: username
    };
    const { data } = await axiosInstance.put(`my-vehicle/set-user-name`, body);
    return data;
  } catch (error) {
    return {
      status: false,
      message: error.toString()
    }
  }
};

export const addUpdateMyVehcile = async (vehicleData) => {  try {
  let body = {
    myVehicle: vehicleData
  };
  const { data } = await axiosInstance.post(`my-vehicle/add-update`, body);
  return data;
} catch (error) {
  return {
    status: false,
    message: error.toString()
  }
}
};

export const selectMyVehicle = async () => {  try {
  const { data } = await axiosInstance.get(`my-vehicle/select`);
  return data;
} catch (error) {
  return {
    status: false,
    message: error.toString()
  }
}
};

