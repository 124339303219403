import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useState, useEffect } from "react";

import { ListGroup, Card, Button, Modal, Badge } from "react-bootstrap";

import profileTypeList from "../../utils/profile-type";

import { CardItemContainer } from "./style";

import { format } from "date-fns";

import axiosInstance from "../../services/AxiosInstance";

import { toast } from "react-toastify";
import clsx from 'clsx';
import { getAllCards } from "../../redux/actions/cardActions";

//import cardBG from "../../images/profile-bg-1.png";
//import cardBG2 from "../../images/profile-bg-2.png";

const cardBG = "https://dashboard.tap1ce.com/media/profile-bg-1.png";
const cardBG2 = "https://dashboard.tap1ce.com/media/profile-bg-2.png";

const CardItem = ({ card, isEven, onDeactivate }) => {
  const [profileType, setProfileType] = useState(-1);

  const [deactivateModel, setDeactivateModel] = useState(false);

  useEffect(() => {
    //console.log("state - "+state);
    //console.log("state - "+JSON.stringify(state));

    const profileTypeIndex = profileTypeList.findIndex((x) =>
      card.activeProfile != null ? x.type === card.activeProfile.type : false
    );

    if (profileTypeIndex != -1) {
      if (profileTypeIndex == 6) {
        setProfileType(0);
      } else {
        setProfileType(profileTypeIndex);
      }
    } else {
      setProfileType(0);
    }
  }, []);

  function fullName() {
    if (profileType == -1) {
      return "";
    }

    if (card.activeProfile) {
      var name = card.activeProfile.name;

      if (
        !(card.activeProfile.namePrefix === "undefined") &&
        card.activeProfile.namePrefix
      ) {
        name = card.activeProfile.namePrefix + " " + name;
      }
      return name;
    } else {
      return "";
    }
  }

  async function onDeactivateClick() {
    let data;
    if (card.status === "active") {
      data = await axiosInstance.post("cards/deactivate", {
        card_id: card._id,
      });
    }
    else {
      data = await axiosInstance.post("cards/activate", {
        card_id: card._id,
      });
    }
    data = data.data;

    if (data.status) {
      console.log(data.message);
      toast.success(data.message);
      setDeactivateModel(false);
      onDeactivate();
    } else {
      toast.warn(data.message);
    }
  }

  // if (card.activeProfile.namePrefix === "undefined") {
  //   card.activeProfile.namePrefix = "";
  // }

  return (
    <CardItemContainer className="mt-2">
      <Modal show={deactivateModel} onHide={() => setDeactivateModel(false)}>
        <Modal.Header>
          <Modal.Title>
            {card.status === "active" ? "Deactivate" : "Active"} Card
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {card.status === "active" ? "Deactivate" : "active"} your card?
          {/* <p>
            If you deactivate your card, you will permanently lose your profile.
          </p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeactivateModel(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={() => onDeactivateClick()}>
            {card.status === "active" ? "Deactivate" : "active"}
          </Button>
        </Modal.Footer>
      </Modal>

      {profileType == -1 || card.activeProfile == null ? (
        <div>
          <Link to={"/cards/" + card.id}>
            <Card>
              <Card.Img variant="top" src={isEven ? cardBG2 : cardBG} />

              <div className={"card-content text-left "}>
                <h2
                  className={
                    "h6 font-weight-bold " +
                    (isEven ? "text-grey" : "text-white") +
                    " no-profile-active"
                  }
                >
                  No Profile Active
                </h2>
              </div>
            </Card>
          </Link>

          <div className="row py-2">
            <div className="col">
              <Link
                to={"/cards/" + card.id}
                className="btn btn-sm btn-outline-light btn-block text-white bg-success"
              >
                View Profiles
              </Link>
            </div>
            <div className="col">
              <Button
                onClick={() => setDeactivateModel(true)}
                className={clsx("btn btn-sm btn-block ", { "btn-danger": card.status === "active", "btn-primary": card.status !== "active" })}
              >
                {card.status === "active" ? "Deactivate" : "Active"}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Link to={"/cards/" + card.id}>
            {" "}
            <Card className="offer-default">
              <div className="card-expire" style={{
                marginTop: "-9px",
                marginRight: "-6px"
              }}>
                {(card.orderId?.isTrial ?? false) ? (
                  <Badge bg="info" pill as="span" text="light">
                    Expire{" "}
                    {format(
                      new Date(card.orderId?.trialExpireDate),
                      "dd/MM/yyyy"
                    )}
                  </Badge>
                ) : (
                  ""
                )}
              </div>

              {card.isCorporate ? (
                <div className="tag">
                  <div className="shape">
                    <div className="shape-text">Trial</div>
                  </div>
                </div>
              ) : (
                <>{/* */}</>
              )}

              {card.isCorporate ? (
                <div className="tag">
                  <div className="shape">
                    <div className="shape-text">corporate</div>
                  </div>
                </div>
              ) : (
                <>
                  {/* <div className="card-expire"><p className={(isEven ? "text-black" : "text-white")}>{card.expiredAt? "Expire "+format(new Date(card.expiredAt), "dd/MM/yyyy"):""}</p> </div>
                   */}
                </>
              )}

              <Card.Img variant="top" src={isEven ? cardBG2 : cardBG} />

              <div className={"card-taps text-left"}>
                <h3
                  className={
                    "mb-0 title font-weight-bold " +
                    (isEven ? "text-grey" : "text-white")
                  }
                >
                  {card.taps ? card.taps : "0"} Taps{", "}
                  {card.uniqueTaps ? card.uniqueTaps : "0"} Unique Taps
                  <br />
                  {card.activeProfile.profileName
                    ? card.activeProfile.profileName
                    : ""}
                </h3>
              </div>

              <div
                className={
                  "card-content text-left " +
                  (isEven ? "text-grey" : "text-white")
                }
              >
                <h2
                  className={
                    "h6 font-weight-bold mb-0 " +
                    (isEven ? "text-grey" : "text-white")
                  }
                >
                  <i class={profileTypeList[profileType].icon}></i>
                  &nbsp;
                  {profileTypeList[profileType].type === "business"
                    ? fullName()
                    : profileTypeList[profileType].name}
                </h2>
                <p
                  className={
                    "text-small sort-link marquee " +
                    (isEven ? "text-grey" : "text-white")
                  }
                >
                  <span>
                    {profileTypeList[profileType].type === "business"
                      ? card.activeProfile.company
                      : card.activeProfile.link}
                  </span>
                </p>
              </div>
            </Card>
          </Link>

          <div className="row py-2">
            <div className="col">
              <Link
                to={"/cards/" + card.id}
                className="btn btn-sm btn-outline-light btn-block text-white bg-success "
              >
                View Profiles
              </Link>
            </div>
            <div className="col">
              <Button
                onClick={() => setDeactivateModel(true)}
                className={clsx("btn btn-sm btn-block ", { "btn-danger": card.status === "active", "btn-primary": card.status !== "active" })}
              >
                {card.status === "active" ? "Deactivate" : "Active"}
              </Button>
            </div>
          </div>
        </div>
      )
      }
    </CardItemContainer >
  );
};

export default CardItem;
